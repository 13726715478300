import {FC, useState} from 'react';
import axios from 'axios';
import {hooks} from '../hooks';
import {custom} from '../custom';
import {svg} from '../assets/svg';
import {theme} from '../constants';
import {components} from '../components';
import api from '../api/api';
import {useUser} from '../contexts/UserContext';

const renderHeader = (): JSX.Element => {
  return <components.Header goBack={true} />;
};

export const SignUp: FC = (): JSX.Element => {
  const navigate = hooks.useAppNavigate();
  const {setUser} = useUser();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [formData, setFormData] = useState({
    fullName: '',
    userName: '',
    email: '',
    password: '',
    confirmPassword: '',
    role: 'User',
  });

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  const handleSignUp = async () => {
    if (formData.password !== formData.confirmPassword) {
      alert('Passwords do not match!');
      return;
    }

    try {
      const response = await api.post('/users/register', formData);
      if (response.status === 201) {
        console.log(response.data);
        localStorage.setItem('user', JSON.stringify(response.data));
        setUser(response.data);
        navigate('/SignUpAccountCreated');
      }
    } catch (error: any) {
      alert(error.response.data.message);
    }
  };

  const renderContent = (): JSX.Element => {
    return (
      <div style={{padding: '50px 20px 20px 20px'}}>
        <components.Line style={{marginBottom: 14}} />
        <h1
          style={{
            margin: 0,
            textAlign: 'center',
            ...theme.fonts.Mulish_700Bold,
            color: theme.colors.mainColor,
            fontSize: 32,
            lineHeight: 1.2,
            textTransform: 'capitalize',
            marginBottom: 40,
          }}
        >
          Sign up
        </h1>
        <custom.InputField
          label='Full Name'
          name='fullName'
          placeholder='Full Name'
          icon={<svg.InputCheckSvg />}
          containerStyle={{marginBottom: 20}}
          value={formData.fullName}
          onChange={handleInputChange}
        />
        <custom.InputField
          label='Username'
          name='userName'
          placeholder='username'
          icon={<svg.InputCheckSvg />}
          containerStyle={{marginBottom: 20}}
          value={formData.userName}
          onChange={handleInputChange}
        />
        <custom.InputField
          label='Email'
          name='email'
          icon={<svg.InputCheckSvg />}
          placeholder='user@mail.com'
          containerStyle={{marginBottom: 20}}
          value={formData.email}
          onChange={handleInputChange}
        />
        <custom.InputField
          clickable={true}
          label='Password'
          name='password'
          placeholder='Password'
          type={showPassword ? 'text' : 'password'}
          icon={
            <span
              onClick={() => setShowPassword(!showPassword)}
              style={{cursor: 'pointer'}}
            >
              {showPassword ? <svg.EyeSvg /> : <svg.EyeOffSvg />}
            </span>
          }
          containerStyle={{marginBottom: 20}}
          value={formData.password}
          onChange={handleInputChange}
        />

        <custom.InputField
          clickable={true}
          label='Confirm Password'
          name='confirmPassword'
          placeholder='Confirm Password'
          type={showConfirmPassword ? 'text' : 'password'}
          icon={
            <span
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              style={{cursor: 'pointer'}}
            >
              {showConfirmPassword ? <svg.EyeSvg /> : <svg.EyeOffSvg />}
            </span>
          }
          containerStyle={{marginBottom: 20}}
          value={formData.confirmPassword}
          onChange={handleInputChange}
        />
        <custom.InputField
          label='Role'
          name='role'
          type='Select'
          value={formData.role}
          onChange={handleInputChange}
          containerStyle={{marginBottom: 20}}
          options={['User', 'Admin', 'Trustee', 'Supplier']}
        />

        <components.Button
          title='Sign up'
          onClick={handleSignUp}
          style={{marginBottom: 20}}
        />
        <div
          style={{flexDirection: 'row', display: 'flex', alignItems: 'center'}}
        >
          <span
            style={{
              marginRight: 4,
              ...theme.fonts.Mulish_400Regular,
              color: theme.colors.textColor,
              fontSize: 16,
              lineHeight: 1.7,
            }}
          >
            Already have an account?
          </span>
          <span
            style={{
              ...theme.fonts.Mulish_400Regular,
              color: theme.colors.mainColor,
              fontSize: 16,
              lineHeight: 1.7,
              cursor: 'pointer'
            }}
            onClick={() => {
              navigate('/');
            }}
          >
            Sign in.
          </span>
        </div>
      </div>
    );
  };

  return (
    <>
      {renderHeader()}
      {renderContent()}
    </>
  );
};
