import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import axios from 'axios'; // Import Axios

import {custom} from '../custom';
import {svg} from '../assets/svg';
import {theme} from '../constants';
import {components} from '../components';
import api from '../api/api';
import {useUser} from '../contexts/UserContext';

export const SignIn: React.FC = () => {
  const navigate = useNavigate();

  const [userName, setUserName] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState(false);
  const {setUser} = useUser();

  const handleSignIn = async () => {
    try {
      const response = await api.post('/users/login', {
        userName,
        password,
      });

      if (response.status === 200) {
        localStorage.setItem('user', JSON.stringify(response.data));
        setUser(response.data);
        navigate('/TabNavigator');
      }
    } catch (error: any) {
      alert(
        error.response.data.message || 'An error occurred while signing in.',
      );
    }
  };

  const renderHeader = () => <components.Header title='Sign in' />;

  const renderContent = (): JSX.Element => (
    <div style={{padding: '50px 20px 20px 20px'}}>
      <components.Line style={{marginBottom: 14}} />
      <h1
        style={{
          margin: 0,
          textAlign: 'center',
          ...theme.fonts.Mulish_700Bold,
          color: theme.colors.mainColor,
          fontSize: 32,
          lineHeight: 1.2,
          textTransform: 'capitalize',
          marginBottom: 14,
        }}
      >
        Welcome back!
      </h1>
      <p
        style={{
          margin: 0,
          color: theme.colors.textColor,
          fontSize: 16,
          lineHeight: 1.6,
          textAlign: 'center',
          marginTop: 0,
          marginBottom: 40,
          ...theme.fonts.Mulish_400Regular,
        }}
      >
        Sign in to continue
      </p>
      <div>
        <custom.InputField
          value={userName}
          label='Username'
          name='usrname'
          icon={<svg.InputCheckSvg />}
          containerStyle={{marginBottom: 20}}
          placeholder='Username'
          onChange={e => setUserName(e.target.value)}
        />
        <custom.InputField
        value={password}
        label='Password'
        name='password'
        placeholder='Enter your password'
        icon={
          <span onClick={() => setShowPassword(!showPassword)} style={{ cursor: 'pointer' }}>
            {showPassword ? <svg.EyeSvg /> : <svg.EyeOffSvg />}
          </span>
        }
        containerStyle={{ marginBottom: 20 }}
        type={showPassword ? 'text' : 'password'}
        onChange={(e) => setPassword(e.target.value)}
      />
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'end',
          marginBottom: 30,
        }}
      >
        {/* Forgot password */}
        <button
          onClick={() => navigate('/ForgotPassword')}
          style={{
            color: theme.colors.mainColor,
            fontSize: 16,
            backgroundColor: 'transparent',
            ...theme.fonts.Mulish_400Regular,
          }}
        >
          Forgot password?
        </button>
      </div>
      <components.Button
        title='Sign in'
        style={{marginBottom: 20}}
        onClick={handleSignIn}
      />
      <div
        style={{display: 'flex', alignItems: 'center', flexDirection: 'row'}}
      >
        <span
          style={{
            marginRight: 4,
            ...theme.fonts.Mulish_400Regular,
            fontSize: 16,
            lineHeight: 1.3,
            color: theme.colors.textColor,
          }}
        >
          Don’t have an account?
        </span>
        <span
          style={{
            ...theme.fonts.Mulish_400Regular,
            fontSize: 16,
            lineHeight: 1.3,
            cursor: 'pointer',
            color: theme.colors.mainColor,
          }}
          onClick={() => navigate('/SignUp')}
        >
          Sign up.
        </span>
      </div>
    </div>
  );

  return (
    <>
      {renderHeader()}
      {renderContent()}
    </>
  );
};
