import {useEffect, useState, FC} from 'react';
import * as Accordion from '@radix-ui/react-accordion';
import {theme} from '../constants';
import {components} from '../components';
import {useUser} from '../contexts/UserContext';
import api from '../api/api';
import {hooks} from '../hooks';
import {custom} from '../custom';

export const OrderHistory: FC = () => {
  const [loading, setLoading] = useState(false);
  const [ordersData, setOrdersData] = useState<any[]>([]);
  const {user} = useUser();
  const [userId, setUserId] = useState<string | null>(null);
  const [userRole, setUserRole] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModelOpen] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState<string | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<string>('Verified');
  const [remark, setRemark] = useState<string>('');
  const [amount, setAmount] = useState<string>('');
  const navigate = hooks.useAppNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (user && user._id) {
      setUserId(user._id);
      setUserRole(user.role);
    } else {
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    if (userId) {
      if (userRole === 'User') getUserOrders();
      else if (userRole === 'Admin') getAdminOrders();
      else if (userRole === 'Supplier') getSupplierOrders();
    }
  }, [userId]);

  const getUserOrders = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/prescription/${userId}`, {
        headers: {
          Authorization: `Bearer ${user ? user.token : null}`,
        },
      });
      setOrdersData(response.data);
    } catch (error: any) {
      console.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const getAdminOrders = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/prescription`, {
        headers: {
          Authorization: `Bearer ${user ? user.token : null}`,
        },
      });
      setOrdersData(response.data);
      console.log(response.data);
    } catch (error: any) {
      console.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const getSupplierOrders = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/prescription/supplier`, {
        headers: {
          Authorization: `Bearer ${user ? user.token : null}`,
        },
      });
      setOrdersData(response.data);
      console.log("Supplier",response.data);
    } catch (error: any) {
      console.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyClick = (orderId: string, call : string) => {
    console.log(orderId);
    setSelectedOrderId(orderId);

    if(call === 'delivered'){
      setIsUpdateModelOpen(true);
    }else{
      setIsModalOpen(true);
    }
  };

  const handleSubmitVerification = async () => {
    if (!selectedOrderId) return;
    const verifiedByAdmin = selectedStatus === 'Verified';
    try {
      //send Quotation
      if(userRole === 'Supplier' && !isUpdateModalOpen){
        const response = await api.post(
           `/workflow/quotation/`,
          {
            supplierId : userId,
            prescriptionId : selectedOrderId,
            amount,
            remarks: remark,
          },
          {
            headers: {
              Authorization: `Bearer ${user ? user.token : null}`,
            },
          },
        );
        console.log(response.data);

        setIsModalOpen(false);
      }else{
        //approve by admin || supplier
        const payload = {
          status: selectedStatus,
          verifiedByAdmin : true,
          ...(isUpdateModalOpen ? { supplierRemarks: remark } :  { remarks: remark }),
        };
        
        const response = await api.patch(
          `/prescription/${selectedOrderId}`,
          {
           payload
          },
          {
            headers: {
              Authorization: `Bearer ${user ? user.token : null}`,
            },
          },
        );
  
        console.log(response.data);
        setOrdersData(prevOrders =>
          prevOrders.map(order =>
            order._id === selectedOrderId
              ? {...order, status: selectedStatus, remarks: remark}
              : order,
          ),
        );
        setIsModalOpen(false);
        setIsUpdateModelOpen(false);
      }
    } catch (error: any) {
      console.error(error.response.data.message);
    }
  };

  

  const renderHeader = () => {
    return <components.Header goBack={true} title='Orders' />;
  };

  const renderContent = (): JSX.Element => {
    if (loading) return <components.Loader />;
    
    return (
      <div style={{padding: '10px 0 20px 0'}}>
        <Accordion.Root type='single' collapsible={true}>
          {ordersData.length > 0 ? (
            ordersData.map((order, index) => {
              const orderId = `#${order._id.slice(-6)}`; // Get the last 5 digits of the order ID
              const orderStatusColor =
                order.status.toLowerCase() === 'delivered'
                  ? '#51BA74'
                  : order.status.toLowerCase() === 'pending' ||
                    order.status.toLowerCase() === 'verified'
                  ? '#F5C102'
                  : '#FF4343'; // Assign colors based on status

              return (
                <Accordion.Item key={index} value={order._id}>
                  <Accordion.Trigger
                    style={{
                      flexDirection: 'column',
                      width: '100%',
                      display: 'flex',
                      padding: '10px 20px 18px 20px',
                      borderBottom: '4px solid #E8EFF4',
                    }}
                  >
                    <div
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 7,
                      }}
                    >
                      <h5
                        style={{
                          margin: 0,
                          ...theme.fonts.Mulish_600SemiBold,
                          fontSize: 16,
                          color: theme.colors.mainColor,
                          lineHeight: 1.2,
                        }}
                      >
                        {orderId}
                      </h5>
                        <span
                          style={{
                            ...theme.fonts.Mulish_400Regular,
                            fontSize: 14,
                            textTransform: 'capitalize',
                            lineHeight: 1.5,
                            color: orderStatusColor,
                          }}
                        >
                          {order.status}
                        </span>

                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        alignItems: 'center',
                      }}
                    >
                      <span
                        style={{
                          ...theme.fonts.Mulish_400Regular,
                          fontSize: 12,
                          color: theme.colors.textColor,
                          lineHeight: 1.5,
                        }}
                      >
                        {new Date(order.createdAt).toLocaleString('en-US', {
                          month: 'short',
                          day: '2-digit',
                          year: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit',
                        })}
                      </span>
                      <span
                        style={{
                          ...theme.fonts.Mulish_700Bold,
                          fontSize: 12,
                          color: theme.colors.mainColor,
                          lineHeight: 1.5,
                        }}
                      >
                        For: {order.forWho}
                      </span>
                    </div>
                  </Accordion.Trigger>
                  <Accordion.Content
                    style={{
                      backgroundColor: '#F8FAFC',
                      borderBottom: '4px solid #E8EFF4',
                      padding: '16px 24px',
                      borderRadius: '8px',
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                    }}
                  >
                    {userRole !== 'User' && (
                      <div style={{marginBottom: '12px'}}>
                        <div style={{margin: 0, color: '#333'}}>
                          <div style={{marginBottom: '12px'}}>
                            <strong>Full Name:</strong> {order.userId.fullName}
                          </div>
                          <div style={{marginBottom: '12px'}}>
                            <strong>Email:</strong> {order.userId.email}
                          </div>
                        </div>
                      </div>
                    )}
                    <div style={{marginBottom: '12px'}}>
                      <strong style={{display: 'block', marginBottom: '4px'}}>
                        Note:
                      </strong>
                      <p style={{margin: 0, color: '#333'}}>{order.note}</p>
                    </div>

                    <div style={{marginBottom: '12px'}}>
                      <strong style={{display: 'block', marginBottom: '4px'}}>
                        Address:
                      </strong>
                      <p style={{margin: 0, color: '#333'}}>
                        {order.address.streetNo} {order.address.streetName},{' '}
                        {order.address.suburb}, {order.address.estate}
                      </p>
                    </div>

                    <div style={{marginBottom: '12px'}}>
                      <strong style={{display: 'block', marginBottom: '8px'}}>
                        Prescription Image:
                      </strong>
                      <div
                        style={{
                          width: '100%',
                          height: '200px',
                          overflow: 'hidden',
                          borderRadius: '8px',
                          border: '1px solid #E8EFF4',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <img
                          src={order.prescriptionImage}
                          alt='Prescription'
                          style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            objectFit: 'cover',
                          }}
                        />
                      </div>
                    </div>
                    {order.remarks && (
                      <div style={{marginBottom: '12px'}}>
                        <strong style={{display: 'block', marginBottom: '4px'}}>
                          Remark:
                        </strong>
                        <p style={{margin: 0, color: '#333'}}>
                          {order.remarks}
                        </p>
                      </div>
                    )}
                    <div style={{display: 'flex', justifyContent: 'end'}}>
                      {userRole === 'Admin' && order.status !== 'Verified' ? (
                        <button
                          onClick={() => handleVerifyClick(order._id,'')}
                          style={{
                            backgroundColor: '#ff0000',
                            color: 'white',
                            padding: '5px 10px',
                            border: 'none',
                            borderRadius: '4px',
                            cursor: 'pointer',
                          }}
                        >
                          Verify
                        </button>
                      ) : null}
                    </div>
                    <div style={{display: 'flex', justifyContent: 'end'}}>
                      {userRole === 'Supplier' && order.status === 'Verified' ? (
                        <button
                          onClick={() => handleVerifyClick(order._id,'')}
                          style={{
                            backgroundColor: '#ff0000',
                            color: 'white',
                            padding: '5px 10px',
                            border: 'none',
                            borderRadius: '4px',
                            cursor: 'pointer',
                          }}
                        >
                          Add Quotation
                        </button>
                      ) : null}
                      {userRole === 'Supplier' && order.status === 'Approved' ? (
                        <button
                          onClick={() =>
                            handleVerifyClick(order._id, 'delivered')
                          }
                          style={{
                            backgroundColor: '#ff00ff',
                            color: 'white',
                            padding: '5px 10px',
                            marginLeft: '10px',
                            border: 'none',
                            borderRadius: '4px',
                            cursor: 'pointer',
                          }}
                        >
                          Update
                        </button>
                      ) : null}
                    </div>
                  </Accordion.Content>
                </Accordion.Item>
              );
            })
          ) : (
            <div style={{padding: '50px 20px 20px 20px'}}>
              <img
                src={require('../assets/icons/08.png')}
                alt='order successful'
                style={{
                  width: 225.18,
                  display: 'block',
                  margin: '0 auto',
                  marginBottom: 14,
                }}
              />
              <h2
                style={{
                  margin: 0,
                  textAlign: 'center',
                  ...theme.fonts.Mulish_700Bold,
                  fontSize: 22,
                  lineHeight: 1.2,
                  color: theme.colors.mainColor,
                  marginBottom: 14,
                  textTransform: 'capitalize',
                  whiteSpace: 'pre-line',
                }}
              >
                No Orders Available!
              </h2>
            </div>
          )}
        </Accordion.Root>
      </div>
    );
  };

  const renderModal = (): JSX.Element | null => {
    if (!isModalOpen) return null;

    return (
      <div
        style={{
          position: 'fixed',
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1000,
        }}
      >
        <div
          style={{
            backgroundColor: '#fff',
            padding: '20px',
            borderRadius: '12px',
            width: '400px',
            maxWidth: '90%',
            boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
            position: 'relative',
          }}
        >
          <h3
            style={{marginBottom: '20px', fontSize: '18px', fontWeight: 'bold'}}
          >
            {userRole === 'Supplier' ? 'Add Quotation' : 'Order Status'}
          </h3>

          {userRole !== 'Supplier' && (
            <div style={{marginBottom: '15px'}}>
              <label style={{marginRight: '15px', fontSize: '14px'}}>
                <input
                  type='radio'
                  name='status'
                  value='Verified'
                  checked={selectedStatus === 'Verified'}
                  onChange={() => setSelectedStatus('Verified')}
                  style={{marginRight: '8px'}}
                />
                Verified
              </label>
              <label style={{fontSize: '14px'}}>
                <input
                  type='radio'
                  name='status'
                  value='Rejected'
                  checked={selectedStatus === 'Rejected'}
                  onChange={() => setSelectedStatus('Rejected')}
                  style={{marginRight: '8px'}}
                />
                Rejected
              </label>
            </div>
          )}
          {userRole === 'Supplier' && (
            <div style={{marginBottom: '20px'}}>
              <custom.InputField
                name='amount'
                placeholder='Enter Amount.'
                value={amount}
                onChange={e => setAmount(e.target.value)}
              />
            </div>
          )}
          <div style={{marginBottom: '20px'}}>
            <textarea
              placeholder='Add a remark...'
              value={remark}
              onChange={e => setRemark(e.target.value)}
              style={{
                width: '100%',
                padding: '10px',
                borderRadius: '10px',
                border: '1px solid rgb(232 239 244)',
                fontSize: '14px',
                minHeight: '80px',
                resize: 'vertical',
              }}
            />
          </div>

          <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            <button
              onClick={() => setIsModalOpen(false)}
              style={{
                backgroundColor: '#ffffff00',
                color: '#ff00ff',
                borderRadius: '4px',
                padding: '8px 12px',
                cursor: 'pointer',
                fontSize: '14px',
              }}
            >
              Cancel
            </button>
            <button
              onClick={handleSubmitVerification}
              style={{
                backgroundColor: '#ff00ff',
                color: 'white',
                border: 'none',
                borderRadius: '4px',
                padding: '8px 12px',
                cursor: 'pointer',
                fontSize: '14px',
                fontWeight: '600',
                marginRight: '10px',
              }}
            >
              Send to {userRole === 'Supplier' ? 'Trustee' : 'Supplier'}
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderUpdateModal = (): JSX.Element | null => {
    if (!isUpdateModalOpen) return null;

    return (
      <div
        style={{
          position: 'fixed',
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1000,
        }}
      >
        <div
          style={{
            backgroundColor: '#fff',
            padding: '20px',
            borderRadius: '12px',
            width: '400px',
            maxWidth: '90%',
            boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
            position: 'relative',
          }}
        >
          <h3
            style={{marginBottom: '20px', fontSize: '18px', fontWeight: 'bold'}}
          >
            Order Status
          </h3>
            <div style={{marginBottom: '15px'}}>
              <label style={{marginRight: '15px', fontSize: '14px'}}>
                <input
                  type='radio'
                  name='status'
                  value='Delivered'
                  checked={selectedStatus === 'Delivered'}
                  onChange={() => setSelectedStatus('Delivered')}
                  style={{marginRight: '8px'}}
                />
                Delivered
              </label>
              <label style={{fontSize: '14px'}}>
                <input
                  type='radio'
                  name='status'
                  value='Not Delivered'
                  checked={selectedStatus === 'Not Delivered'}
                  onChange={() => setSelectedStatus('Not Delivered')}
                  style={{marginRight: '8px'}}
                />
                Not Delivered
              </label>
            </div>
  
          <div style={{marginBottom: '20px'}}>
            <textarea
              placeholder='Add a remark...'
              value={remark}
              onChange={e => setRemark(e.target.value)}
              style={{
                width: '100%',
                padding: '10px',
                borderRadius: '10px',
                border: '1px solid rgb(232 239 244)',
                fontSize: '14px',
                minHeight: '80px',
                resize: 'vertical',
              }}
            />
          </div>

          <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            <button
              onClick={() => setIsUpdateModelOpen(false)}
              style={{
                backgroundColor: '#ffffff00',
                color: '#ff00ff',
                borderRadius: '4px',
                padding: '8px 12px',
                cursor: 'pointer',
                fontSize: '14px',
              }}
            >
              Cancel
            </button>
            <button
              onClick={handleSubmitVerification}
              style={{
                backgroundColor: '#ff00ff',
                color: 'white',
                border: 'none',
                borderRadius: '4px',
                padding: '8px 12px',
                cursor: 'pointer',
                fontSize: '14px',
                fontWeight: '600',
                marginRight: '10px',
              }}
            >
              {isUpdateModalOpen ? 'Update Order' : (
               <>
               Send to {userRole === 'Supplier' ? 'Trustee' : 'Supplier'}
               </> 
              )}
            </button>
          </div>
        </div>
      </div>
    );
  };
 

  return (
    <>
      {renderHeader()}
      {renderContent()}
      {renderModal()}
      {renderUpdateModal()}
    </>
  );
};
