import axios from 'axios';
import {useEffect, useState, FC} from 'react';
import * as Accordion from '@radix-ui/react-accordion';
import {theme} from '../constants';
import {components} from '../components';
import {useUser} from '../contexts/UserContext';
import api from '../api/api';
import {hooks} from '../hooks';
import {flex} from '../constants/flex';
import {custom} from '../custom';

type PayloadType = {
  status: string;
  remarks?: string;
  trusteeRemarks?: string;
};

export const Quotation: FC = () => {
  const [loading, setLoading] = useState(false);
  const [ordersData, setOrdersData] = useState<any[]>([]);
  const {user} = useUser();
  const [userId, setUserId] = useState<string | null>(null);
  const [userRole, setUserRole] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPrescriptionModalOpen, setIsPrescriptionModalOpen] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState<string | null>(null);
  const [selectedPrescriptionId, setSelectedPrescriptionId] = useState< string | null >(null);
  const [viewOrder, setViewOrder] = useState<Record<string, any>>({});
  const [selectedStatus, setSelectedStatus] = useState<string>('Verified');
  const [remark, setRemark] = useState<string>('');
  const [amount, setAmount] = useState<string>('');
  const [sendToAdmin, setSendToAdmin] = useState(false);

  const navigate = hooks.useAppNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (user && user._id) {
      setUserId(user._id);
      setUserRole(user.role);
    } else {
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    if (userId) {
      getUserOrders();
    }
  }, [userId]);

  const getUserOrders = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/workflow/quotation`, {
        headers: {
          Authorization: `Bearer ${user ? user.token : null}`,
        },
      });
      // console.log(response.data);
      setOrdersData(response.data);
    } catch (error: any) {
      console.error(error.response);
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyClick = (order: any, call: string) => {
    // console.log(order._id);
    setSelectedOrderId(order._id);
    setSelectedPrescriptionId(order.prescriptionId._id);
    if (call === 'approve') {
      setIsModalOpen(true);
    } else {
      setIsPrescriptionModalOpen(true);
      setViewOrder(order);
    }
  };

  const handleSubmitVerification = async () => {
    //quotationId
    if (!selectedOrderId) return;

    if(userRole === 'Admin'){
      try {
        const payload = {
          verifiedByAdmin : true,
          status : "Approved Again",
          remarks: remark,
        };

        await api.patch(
          `/prescription/${selectedPrescriptionId}`,
          {
           payload
          },
          {
            headers: {
              Authorization: `Bearer ${user ? user.token : null}`,
            },
          },
        );
      getUserOrders();
      setIsModalOpen(false);
    } catch (error: any) {
      console.error(error.response.data.message);
    }
    }else{
    try {
      const payload: PayloadType = {
        status: selectedStatus,
      };

      if (userRole === 'Supplier') {
        payload.status = 'Quoted Again'; 
        payload.remarks = remark; 
      } else{
        payload.trusteeRemarks = remark;
      }
      await api.patch(
        `/workflow/quotation/${selectedOrderId}/${selectedPrescriptionId}`,
        {
          payload,
        },
        {
          headers: {
            Authorization: `Bearer ${user ? user.token : null}`,
          },
        },
      );

      getUserOrders();
      setIsModalOpen(false);
    } catch (error: any) {
      console.error(error.response.data.message);
    }
  }
  };

  const renderHeader = () => {
    return <components.Header goBack={true} title='Quotations' />;
  };

  const renderContent = (): JSX.Element => {
    if (loading) return <components.Loader />;

    return (
      <div style={{padding: '10px 0 20px 0'}}>
        <Accordion.Root type='single' collapsible={true}>
          {ordersData.length > 0 ? (
            ordersData.map((order, index) => {
              const orderStatusColor =
                order.status.toLowerCase() === 'Approved'
                  ? '#51BA74'
                  : order.status.toLowerCase() === 'Pending' ||
                    order.status.toLowerCase() === 'Rejected'
                  ? '#F5C102'
                  : '#FF4343';

              return (
                <Accordion.Item key={index} value={order._id}>
                  <Accordion.Trigger
                    style={{
                      flexDirection: 'column',
                      width: '100%',
                      display: 'flex',
                      padding: '10px 20px 18px 20px',
                      borderBottom: '4px solid #E8EFF4',
                    }}
                  >
                    <div
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 7,
                      }}
                    >
                      <h5
                        style={{
                          margin: 0,
                          ...theme.fonts.Mulish_600SemiBold,
                          fontSize: 16,
                          color: theme.colors.mainColor,
                          lineHeight: 1.2,
                        }}
                      >
                        {order.prescriptionId._id.slice(-6)}
                      </h5>
                      <span
                        style={{
                          ...theme.fonts.Mulish_400Regular,
                          fontSize: 14,
                          textTransform: 'capitalize',
                          lineHeight: 1.5,
                          color: orderStatusColor,
                        }}
                      >
                        {order.prescriptionId.status === 'Approved Again' ? 'Approved Again' : order.status}
                      </span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        alignItems: 'center',
                      }}
                    >
                      <span
                        style={{
                          ...theme.fonts.Mulish_400Regular,
                          fontSize: 12,
                          color: theme.colors.textColor,
                          lineHeight: 1.5,
                        }}
                      >
                        {new Date(order.createdAt).toLocaleString('en-US', {
                          month: 'short',
                          day: '2-digit',
                          year: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit',
                        })}
                      </span>
                      <span
                        style={{
                          ...theme.fonts.Mulish_700Bold,
                          fontSize: 12,
                          color: theme.colors.mainColor,
                          lineHeight: 1.5,
                        }}
                      >
                        Amount: {order.amount}
                      </span>
                    </div>
                  </Accordion.Trigger>
                  <Accordion.Content
                    style={{
                      backgroundColor: '#F8FAFC',
                      borderBottom: '4px solid #E8EFF4',
                      padding: '16px 24px',
                      borderRadius: '8px',
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                    }}
                  >
                    {userRole !== 'Supplier' && (
                      <div style={{marginBottom: '12px'}}>
                        <div style={{margin: 0, color: '#333'}}>
                          <div style={{marginBottom: '12px'}}>
                            <strong>Supplier:</strong>{' '}
                            {order.supplierId.fullName}
                          </div>
                          <div style={{marginBottom: '12px'}}>
                            <strong>Email:</strong> {order.supplierId.email}
                          </div>
                        </div>
                      </div>
                    )}
                    {order.remarks && (
                      <div style={{marginBottom: '12px'}}>
                        <strong style={{display: 'block', marginBottom: '4px'}}>
                          Supplier Remarks:
                        </strong>
                        <p style={{margin: 0, color: '#333'}}>
                          {order.remarks}
                        </p>
                      </div>
                    )}
                    {order.trusteeRemarks && (
                      <div style={{marginBottom: '12px'}}>
                        <strong style={{display: 'block', marginBottom: '4px'}}>
                          Trustee Remarks:
                        </strong>
                        <p style={{margin: 0, color: '#333'}}>
                          {order.trusteeRemarks}
                        </p>
                      </div>
                    )}
                    {order.prescriptionId.remarks && (
                      <div style={{marginBottom: '12px'}}>
                        <strong style={{display: 'block', marginBottom: '4px'}}>
                          Admin Remarks:
                        </strong>
                        <p style={{margin: 0, color: '#333'}}>
                          {order.prescriptionId.remarks}
                        </p>
                      </div>
                    )}
                    <div style={{display: 'flex', justifyContent: 'end'}}>
                      <div style={{display: 'flex', justifyContent: 'end'}}>
                        <button
                          onClick={() => handleVerifyClick(order, '')}
                          style={{
                            backgroundColor: '#ff0000',
                            color: 'white',
                            padding: '5px 10px',
                            border: 'none',
                            borderRadius: '4px',
                            cursor: 'pointer',
                          }}
                        >
                          View Order
                        </button>
                      </div>
                      {userRole === 'Trustee' ? (
                        <button
                          onClick={() => handleVerifyClick(order, 'approve')}
                          style={{
                            backgroundColor: '#ff00ff',
                            color: 'white',
                            padding: '5px 10px',
                            marginLeft: '10px',
                            border: 'none',
                            borderRadius: '4px',
                            cursor: 'pointer',
                          }}
                        >
                          Approve
                        </button>
                      ) : null}
                      {userRole === 'Supplier' ? (
                        <button
                          onClick={() => handleVerifyClick(order, 'approve')}
                          style={{
                            backgroundColor: '#ff00ff',
                            color: 'white',
                            padding: '5px 10px',
                            marginLeft: '10px',
                            border: 'none',
                            borderRadius: '4px',
                            cursor: 'pointer',
                          }}
                        >
                          Update Quote
                        </button>
                      ) : null}
                      {userRole === 'Admin' ? (
                        <button
                          onClick={() => handleVerifyClick(order, 'approve')}
                          style={{
                            backgroundColor: '#ff00ff',
                            color: 'white',
                            padding: '5px 10px',
                            marginLeft: '10px',
                            border: 'none',
                            borderRadius: '4px',
                            cursor: 'pointer',
                          }}
                        >
                          Update Remark
                        </button>
                      ) : null}
                    </div>
                  </Accordion.Content>
                </Accordion.Item>
              );
            })
          ) : (
            <div style={{padding: '50px 20px 20px 20px'}}>
              <img
                src={require('../assets/icons/08.png')}
                alt='order successful'
                style={{
                  width: 225.18,
                  display: 'block',
                  margin: '0 auto',
                  marginBottom: 14,
                }}
              />
              <h2
                style={{
                  margin: 0,
                  textAlign: 'center',
                  ...theme.fonts.Mulish_700Bold,
                  fontSize: 22,
                  lineHeight: 1.2,
                  color: theme.colors.mainColor,
                  marginBottom: 14,
                  textTransform: 'capitalize',
                  whiteSpace: 'pre-line',
                }}
              >
                No Orders Available!
              </h2>
            </div>
          )}
        </Accordion.Root>
      </div>
    );
  };

  const renderModal = (): JSX.Element | null => {
    if (!isModalOpen) return null;

    return (
      <div
        style={{
          position: 'fixed',
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1000,
        }}
      >
        <div
          style={{
            backgroundColor: '#fff',
            padding: '20px',
            borderRadius: '12px',
            width: '400px',
            maxWidth: '90%',
            boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
            position: 'relative',
          }}
        >
          <h3
            style={{marginBottom: '20px', fontSize: '18px', fontWeight: 'bold'}}
          >
            {userRole === 'Supplier' ? 'Update Quotation' : (
              <>
              {userRole === 'Admin' ? 'Update Remark' : 'Quotation Status'}
              </>
            )}
          </h3>

          {userRole !== 'Supplier' && userRole !== 'Admin'  && (
            <div style={{marginBottom: '15px'}}>
              <label style={{marginRight: '15px', fontSize: '14px'}}>
                <input
                  type='radio'
                  name='status'
                  value='Verified'
                  checked={selectedStatus === 'Verified'}
                  onChange={() => setSelectedStatus('Verified')}
                  style={{marginRight: '8px'}}
                />
                Verified
              </label>
              <label style={{fontSize: '14px'}}>
                <input
                  type='radio'
                  name='status'
                  value='Rejected'
                  checked={selectedStatus === 'Rejected'}
                  onChange={() => setSelectedStatus('Rejected')}
                  style={{marginRight: '8px'}}
                />
                Rejected
              </label>
            </div>
          )}
          {selectedStatus === 'Rejected' && (
            <div style={{marginBottom: '15px'}}>
              <p style={{fontSize: '14px', marginBottom: '8px'}}>
                Do you want to send to admin for more clarified remarks?
              </p>
              <label style={{marginRight: '15px', fontSize: '14px'}}>
                <input
                  type='radio'
                  name='sendToAdmin'
                  value='Yes'
                  onChange={() => setSendToAdmin(true)}
                  style={{marginRight: '8px'}}
                />
                Yes
              </label>
              <label style={{fontSize: '14px'}}>
                <input
                  type='radio'
                  name='sendToAdmin'
                  value='No'
                  onChange={() => setSendToAdmin(false)}
                  style={{marginRight: '8px'}}
                />
                No
              </label>
            </div>
          )}
          {userRole === 'Supplier' && (
            <div style={{marginBottom: '20px'}}>
              <custom.InputField
                name='amount'
                placeholder='Enter Amount.'
                value={amount}
                onChange={e => setAmount(e.target.value)}
              />
            </div>
          )}

          <div style={{marginBottom: '20px'}}>
            {userRole === 'Admin' ? (
              <textarea
              placeholder='Update Your Remark...'
              value={remark }
              onChange={e => setRemark(e.target.value)}
              style={{
                width: '100%',
                padding: '10px',
                borderRadius: '6px',
                border: '1px solid #ddd',
                fontSize: '14px',
                minHeight: '80px',
                resize: 'vertical',
              }}
            />
            ):(
              <textarea
              placeholder='Add a remark...'
              value={remark }
              onChange={e => setRemark(e.target.value)}
              style={{
                width: '100%',
                padding: '10px',
                borderRadius: '6px',
                border: '1px solid #ddd',
                fontSize: '14px',
                minHeight: '80px',
                resize: 'vertical',
              }}
            />
            )}
          </div>

          <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            <button
              onClick={() => setIsModalOpen(false)}
              style={{
                backgroundColor: '#ffffff00',
                color: '#ff00ff',
                borderRadius: '4px',
                padding: '8px 12px',
                cursor: 'pointer',
                fontSize: '14px',
              }}
            >
              Cancel
            </button>
            <button
              onClick={handleSubmitVerification}
              style={{
                backgroundColor: '#ff00ff',
                color: 'white',
                border: 'none',
                borderRadius: '4px',
                padding: '8px 12px',
                cursor: 'pointer',
                fontSize: '14px',
                fontWeight: '600',
                marginRight: '10px',
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderPrescriptionModal = (): JSX.Element | null => {
    if (!isPrescriptionModalOpen) return null;

    return (
      <div
        style={{
          position: 'fixed',
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1000,
        }}
      >
        <div
          style={{
            backgroundColor: '#F8FAFC',
            borderBottom: '4px solid #E8EFF4',
            padding: '16px 24px',
            borderRadius: '8px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          }}
        >
          <div style={{marginBottom: '12px'}}>
            <div style={{margin: 0, color: '#333'}}>
              <div style={{marginBottom: '12px'}}>
                <strong>Full Name:</strong>{' '}
                {viewOrder.prescriptionId.userId.fullName}
              </div>
              <div style={{marginBottom: '12px'}}>
                <strong>Email:</strong> {viewOrder.prescriptionId.userId.email}
              </div>
            </div>
          </div>

          <div style={{marginBottom: '12px'}}>
            <strong style={{display: 'block', marginBottom: '4px'}}>
              Note:
            </strong>
            <p style={{margin: 0, color: '#333'}}>
              {viewOrder.prescriptionId.note}
            </p>
          </div>

          <div style={{marginBottom: '12px'}}>
            <strong style={{display: 'block', marginBottom: '4px'}}>
              Address:
            </strong>
            <p style={{margin: 0, color: '#333'}}>
              {viewOrder.prescriptionId.address.streetNo}{' '}
              {viewOrder.prescriptionId.address.streetName},{' '}
              {viewOrder.prescriptionId.address.suburb},{' '}
              {viewOrder.prescriptionId.address.estate}
            </p>
          </div>

          <div style={{marginBottom: '12px'}}>
            <strong style={{display: 'block', marginBottom: '8px'}}>
              Prescription Image:
            </strong>
            <div
              style={{
                width: '100%',
                height: '200px',
                overflow: 'hidden',
                borderRadius: '8px',
                border: '1px solid #E8EFF4',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img
                src={viewOrder.prescriptionId.prescriptionImage}
                alt='Prescription'
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  objectFit: 'cover',
                }}
              />
            </div>
          </div>
          {viewOrder.remarks && (
            <div style={{marginBottom: '12px'}}>
              <strong style={{display: 'block', marginBottom: '4px'}}>
                Admin Remark:
              </strong>
              <p style={{margin: 0, color: '#333'}}>
                {viewOrder.prescriptionId.remarks}
              </p>
            </div>
          )}
          <div style={{display: 'flex', justifyContent: 'end'}}>
            <button
              onClick={() => setIsPrescriptionModalOpen(false)}
              style={{
                backgroundColor: '#ff00ff',
                color: '#fff',
                borderRadius: '4px',
                padding: '8px 12px',
                cursor: 'pointer',
                fontSize: '14px',
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {renderHeader()}
      {renderContent()}
      {renderModal()}
      {renderPrescriptionModal()}
    </>
  );
};
