import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {hooks} from '../../hooks';
import {items} from '../../items';
import {svg} from '../../assets/svg';
import {theme} from '../../constants';
import {RootState} from '../../store';
import {components} from '../../components';
import {useUser} from '../../contexts/UserContext';
import {flex} from '../../constants/flex';

export const Profile: React.FC = () => {
  const navigate = useNavigate();
  const {user} = useUser();
  const [userRole, setUserRole] = useState<string | null>(null);
  const [visible, setVisible] = useState<boolean | null>(false);
  const [visibleTrustee, setVisibleTrustee] = useState<boolean | null>(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (user && user.role) {
      setUserRole(user.role);
    }
  }, [user]);

  useEffect(() => {
    if (userRole) {
      if (userRole !== 'User') setVisible(true);
      if (userRole === 'Trustee') setVisibleTrustee(false);
    }
  }, [userRole]);

  const renderHeader = (): JSX.Element => {
    return (
      <components.Header
        title='My profile'
        burger={true}
        basket={true}
        line={true}
      />
    );
  };

  const renderUserInfo = (): JSX.Element => {
    return (
      <>
        {user ? (
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <div
              style={{
                marginBottom: 29,
                display: 'flex',
                flexDirection: 'column',
                padding: '0 20px',
              }}
            >
              <h3
                style={{
                  fontSize: 20,
                  marginBottom: 4,
                  fontWeight: 'bold',
                  ...theme.fonts.Mulish_700Bold,
                  color: theme.colors.mainColor,
                }}
              >
                {user.userName}
              </h3>
              <span
                style={{
                  fontSize: 14,
                  lineHeight: 1.5,
                  color: theme.colors.textColor,
                  ...theme.fonts.Mulish_400Regular,
                }}
              >
                {userRole}
              </span>
            </div>
            <div>
              <items.ProfileItem
                title=''
                icon={<svg.LogOutSvg />}
                onClick={() => {
                  navigate('/SignOut');
                }}
                navIcon={false}
              />
            </div>
          </div>
        ) : (
          <div>Please Login</div>
        )}
      </>
    );
  };

  const renderMenu = (): JSX.Element => {
    return (
      <>
        {visibleTrustee && (
          <items.ProfileItem
            title='Orders'
            icon={<svg.CalendarSvg />}
            onClick={() => {
              navigate('/OrderHistory');
            }}
          />
        )}
        {visible && (
          <items.ProfileItem
            title='Quotations'
            icon={<svg.UserSvg />}
            onClick={() => navigate('/Quotations')}
          />
        )}
      </>
    );
  };

  const renderContent = () => {
    return (
      <div style={{paddingTop: 40, paddingBottom: 64 + 30}}>
        {renderUserInfo()}
        {renderMenu()}
      </div>
    );
  };

  const renderBottomTabBar = () => {
    return <components.BottomTabBar />;
  };

  return (
    <>
      {renderHeader()}
      {renderContent()}
      {renderBottomTabBar()}
    </>
  );
};
